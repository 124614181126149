import { Navbar, Nav, Container } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import "./Header.css";
import "./Header.mobile.css";
import { ReactComponent as HeaderLogo } from "../../logo-header.svg";
import { useLocation } from "react-router-dom";
import WhatsappButton from "../WhatsappButton/WhatsappButton";

const Header = () => {
  const [switchToggled, setSwitchToggled] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const headerRef = useRef(null);
  const headerReplacementRef = useRef(null);
  const checkToggle = switchToggled ? "active" : "";
  const location = useLocation();

  const toggleHamburger = () => {
    setSwitchToggled(!switchToggled);
  };

  // Retrieves current computed height of header and update headerHeight
  useEffect(() => {
    if (headerRef.current) {
      headerReplacementRef.current.style.height = `${headerRef.current.clientHeight}px`;
    }
    if (localStorage.getItem("contactUs")) {
      setTimeout(() => {
        scrollToContactUs();
        localStorage.removeItem("contactUs");
      }, 1500);
    }
  }, []);

  // Observe the scroller event and update offset
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  });

  // Update header's behavior based on current offset and headerHeight
  useEffect(() => {
    if (offset <= 0) {
      setShowScrollToTop(false);
    } else {
      setShowScrollToTop(true);
    }
  });

  const scrollToContactUs = () => {
    const element = document.getElementById("contactUs");
    if (element) {
      setSwitchToggled(false);
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  //Onclick function to contact us
  const contactUsClick = () => {
    if (location.pathname !== "/") {
      localStorage.setItem("contactUs", true);
      window.location.href = "/";
    }
    scrollToContactUs();
  };

  return (
    <div className="headerDiv">
      <Navbar variant="dark" className="header" ref={headerRef}>
        <Container>
          <Navbar.Brand href="/" className="header-title">
            <HeaderLogo className="header-logo" />
          </Navbar.Brand>
          <Navbar className="header-content">
            <Nav className={`me-auto navbar-links ${checkToggle}`}>
              <Nav.Link
                href="/"
                className={location.pathname === "/" ? "activeNav" : null}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/services"
                className={
                  location.pathname === "/services" ? "activeNav" : null
                }
              >
                Services
              </Nav.Link>
              <span className="nav-link" onClick={() => contactUsClick()}>
                Contact Us
              </span>
            </Nav>
            <div
              className={`hamburger ${checkToggle}`}
              onClick={toggleHamburger}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </Navbar>
        </Container>
      </Navbar>
      <div className="headerReplacement" ref={headerReplacementRef}></div>
      <WhatsappButton />
      {/* <ScrollToTop shouldShow={showScrollToTop} /> */}
    </div>
  );
};

export default Header;
