import { Container } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GetSpaDetailList } from "../SpaDetailList/GetSpaDetailList.gql";
import { Navigate } from "react-router-dom";
import "./BottomCarousel.css";
import Slider from "react-slick";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import BottomCarouselItem from "../BottomCarouselItem/BottomCarouselItem";

function SliderNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-next-custom"}
      onClick={onClick}
    >
      <AiOutlineRight/>
    </div>
  );
}

function SliderPrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-prev-custom"}
      onClick={onClick}
    >
      <AiOutlineLeft/>
    </div>
  );
}

const BottomCarousel = ({ itemId }) => {
  const { loading, data, error } = useQuery(GetSpaDetailList);
  
  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return <Navigate to="/" />;
  }

  const filteredSpa = data.getSpaDetailList.filter((spa) => {
    return spa.id !== itemId;
  });

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SliderNextArrow/>,
    prevArrow: <SliderPrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <div className="bottom-carousel">
      <Container>
        <div className="inner-carousel">
          <div className="carousel-heading">You May Also Like</div>
            <Slider {...settings}>
            {
              filteredSpa.map((item) => {
                return <BottomCarouselItem key={item.id} {...item} />
              })
            }
            </Slider>
        </div>
      </Container>
    </div>
  );
};

export default BottomCarousel;
