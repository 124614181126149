import { useEffect } from "react";
import { useState } from "react";
import './Description.css';

const length = 30;
const Description = (props) => {
    const { desc } = props;

    const [ readMore, setReadMore ] = useState(false);
    const [ readLess, setReadLess ] = useState(false);
    const [ descDisplay, setDescDisplay ]= useState(desc);

    useEffect(() => {
        preprocessDesc();
    }, []);

    const preprocessDesc = () => {
        let descArray = desc.split(" ");
        if (descArray.length >= length) {
            setDescDisplay(descArray.slice(0,length-1).join(" "));
            setReadMore(true);
        }
    }

    const readMoreOnClick = () => {
        setReadMore(false);
        setReadLess(true);
        setDescDisplay(desc);
    }

    const readLessOnClick = () => {
        setReadMore(true);
        setReadLess(false);
        preprocessDesc();
    }

    return (
        <div>
            { descDisplay }
            { readMore ? <span className="descReadAction" onClick={() => {readMoreOnClick()}}> Read more...</span> : null } 
            { readLess ? <span className="descReadAction" onClick={() => {readLessOnClick()}}> Read less...</span> : null }
        </div>
    );
}

export default Description;