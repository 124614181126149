/**
 * Formats price with currency symbol and decimal
 * @param {*} amount 
 * @param {*} symbol 
 * @returns string
 */
export function priceFormatter(amount, symbol = "RM")
{
    return `${symbol} ${Number(amount).toFixed(2)}`;
}//end priceFormatter()

/**
 * Formats duration with time and type
 * @param {*} time 
 * @param {*} type 
 * @returns string
 */
export function timeFormatter(time, type = "min(s)")
{
    return `${time}${type}`;
}//end timeFormatter()

/**
 * Formats double line string into single line by appending ...
 * @param {*} value 
 * @returns string
 */
export function ellipsisFormatter(value)
{
    if (value.split(' ').length > 3) {
        return `${value.split(' ').slice(0, 3).join(' ')} ...`
    } 
    return value;
}//end ellipsisFormatter()
