import { gql } from "@apollo/client";

const CONTACT_US = gql`
  mutation contactUs($name: String!, $email: String!, $message: String) {
    contactUs(name: $name, email: $email, message: $message) {
      status
    }
  }
`;

export const SendContactUs = CONTACT_US;
