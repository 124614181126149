import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie'

const KEY_SESSION = 'SESSID';

/**
 * Set session id
 * @param {*} sessionId 
 */
export function setSessionId(sessionId)
{
    Cookies.set(KEY_SESSION, sessionId, {
        path: "/",
        secure: true,
        sameSite: 'strict'
    });
}

/**
 * Get session id
 * @returns string
 */
export function getSessionId()
{
    if (!hasSessionId()) {
        setSessionId(uuidv4());
    }
    return Cookies.get(KEY_SESSION);
}//end getSessionId()

/**
 * Check if there's session id in cookie storage
 * @returns boolean
 */
export function hasSessionId()
{
    let sessionId = Cookies.get(KEY_SESSION);
    if (typeof sessionId !== 'undefined') {
        return true;
    }
    return false;
}//end hasSessionId()
