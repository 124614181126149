import { BiCopyright } from "react-icons/bi";
import "./Copyright.css";
import "./Copyright.mobile.css";

const Copyright = () => {

  let currentDate = new Date().getFullYear();

  return (
    <div className="copyright">
      <div className="copyright-wrapper">
        <span className="copyright-content">
          <span className="copyright-words">
            Copyright <BiCopyright /> 2023 - {currentDate}. Reflexo Spa Sdn. Bhd. 202201019649 (1465346-K), Silver Thai Spa. All Rights Reserved.
          </span>
        </span>
      </div>
    </div>
  );
};

export default Copyright;
