import { MdError } from "react-icons/md";
import './Error.css';

const Error = () => {
    return (
        <div className="errorDiv">
            <MdError />
            <span>An error occured. Please try again later.</span>
        </div>
    );
}

export default Error;