import { Spinner } from 'react-bootstrap';
import './Loading.css';

const Loading = () => {
    return (
        <div className='loadingDiv'>
            <Spinner animation="border" />
        </div>
    );
}

export default Loading;