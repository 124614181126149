import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { ENDPOINT_GQL } from "../Api/ConstantInterface";
import { getSessionId } from "../Session/Session";

const httpLink = createHttpLink({
  uri: ENDPOINT_GQL
});

const authLink = setContext((_, { headers }) => {
  let token = getSessionId();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
