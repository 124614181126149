import { useEffect, useRef } from 'react';
import './Splash.css';
import './Splash.mobile.css';
import { ReactComponent as SplashLogo } from "../../logo-splash.svg";

const Splash = () => {

    const splashRef = useRef(null);
    const duration = 3000;

    useEffect(() => {
        document.querySelector('body').classList.add('disableScroll');
        let timer = setTimeout(() => {
            document.querySelector('body').classList.remove('disableScroll');
            splashRef.current.className = `${splashRef.current.className} hideSplash`;
        }, duration);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='splashDiv' ref={splashRef}>
            <SplashLogo className='splashLogo'/>
        </div>
    );
};

export default Splash;
