import { gql } from "@apollo/client";

const GET_ABOUT_US = gql`
  query getAboutUs {
    getAboutUs {
      description
    }
  }
`;

export const GetAboutUs = GET_ABOUT_US;
