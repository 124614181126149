import { Row, Container } from "react-bootstrap";
import SpaDetail from "../SpaDetail/SpaDetail";
import "./SpaDetailList.css";
import { useQuery } from "@apollo/client";
import { GetSpaDetailList } from "./GetSpaDetailList.gql";
import Error from "../Error/Error";
import Loading from "../Loading/Loading";

const SpaDetailList = () => {
  const { loading, data, error } = useQuery(GetSpaDetailList, {
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container className="spaDetailList">
      <div className="title-div">
        <span className="spaDetailListTitle">
          <span>Services</span>
        </span>
      </div>
      <Row md="12">
        {data.getSpaDetailList.map((item) => {
          return <SpaDetail key={item.id} {...item} />;
        })}
      </Row>
    </Container>
  );
};

export default SpaDetailList;
