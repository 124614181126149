import { useNavigate } from "react-router-dom";
import { ellipsisFormatter } from "../../modules/Formatter/Formatter";
import { routerSeoMapping } from "../../modules/Router/Router";
import "./BottomCarouselItem.css";

const BottomCarouselItem = (props) => {
  const { id, title, thumbnail } = props;
  const navigate = useNavigate();
  return (
    <div className="filteredItemDiv" title={title}>
      <div
        className="filtered-item"
        onClick={() =>
          navigate(`/services/${routerSeoMapping(title)}`, {
            state: { id: id },
          })
        }
      >
        <img src={thumbnail} title={title} alt={title} />
        <div className="services-detail" title={title}>
          {ellipsisFormatter(title)}
        </div>
      </div>
    </div>
  );
};

export default BottomCarouselItem;
