import { BsInstagram, BsPhone, BsWhatsapp } from "react-icons/bs";
import { SiGooglemaps, SiWhatsapp } from "react-icons/si";
import { FiFacebook } from "react-icons/fi";
import "./SocialMedia.css";
import { useRef } from "react";

const SocialMedia = () => {
  const spaFacebookRef = useRef(null);
  const spaInstagramRef = useRef(null);
  const spaWazeRef = useRef(null);
  const spaGmapRef = useRef(null);
  const spaWhatsapp = useRef(null);

  // Add dummy class to trigger real animation class on touch end
  const onTouchStart = (index) => {
    switch (index) {
      case 0:
        if (spaFacebookRef.current.className.includes("resetHover")) {
          spaFacebookRef.current.className =
            spaFacebookRef.current.className.replace(
              new RegExp(" resetHover", "g"),
              " shouldResetHover"
            );
        } else {
          spaFacebookRef.current.className =
            spaFacebookRef.current.className.replace(
              new RegExp(" resetHover", "g"),
              ""
            );
          spaFacebookRef.current.className = `${spaFacebookRef.current.className} shouldResetHover`;
        }
        break;
      case 1:
        if (spaInstagramRef.current.className.includes("shouldResetHover")) {
          spaInstagramRef.current.className =
            spaInstagramRef.current.className.replace(
              new RegExp(" resetHover", "g"),
              " shouldResetHover"
            );
        } else {
          spaInstagramRef.current.className =
            spaInstagramRef.current.className.replace(
              new RegExp(" resetHover", "g"),
              ""
            );
          spaInstagramRef.current.className = `${spaInstagramRef.current.className} shouldResetHover`;
        }
        break;
      case 2:
        if (spaWazeRef.current.className.includes("shouldResetHover")) {
          spaWazeRef.current.className = spaWazeRef.current.className.replace(
            new RegExp(" resetHover", "g"),
            " shouldResetHover"
          );
        } else {
          spaWazeRef.current.className = spaWazeRef.current.className.replace(
            new RegExp(" resetHover", "g"),
            ""
          );
          spaWazeRef.current.className = `${spaWazeRef.current.className} shouldResetHover`;
        }
        break;
      case 3:
        if (spaGmapRef.current.className.includes("shouldResetHover")) {
          spaGmapRef.current.className = spaGmapRef.current.className.replace(
            new RegExp(" resetHover", "g"),
            " shouldResetHover"
          );
        } else {
          spaGmapRef.current.className = spaGmapRef.current.className.replace(
            new RegExp(" resetHover", "g"),
            ""
          );
          spaGmapRef.current.className = `${spaGmapRef.current.className} shouldResetHover`;
        }
        break;
      case 4:
        if (spaWhatsapp.current.className.includes("shouldResetHover")) {
          spaWhatsapp.current.className = spaWhatsapp.current.className.replace(
            new RegExp(" resetHover", "g"),
            " shouldResetHover"
          );
        } else {
          spaWhatsapp.current.className = spaWhatsapp.current.className.replace(
            new RegExp(" resetHover", "g"),
            ""
          );
          spaWhatsapp.current.className = `${spaWhatsapp.current.className} shouldResetHover`;
        }
        break;
      default:
        break;
    }
  };

  // Remove shouldResetHover class and swap it with real animation class: resetHover
  const onTouchEnd = (index) => {
    switch (index) {
      case 0:
        if (spaFacebookRef.current.className.includes("shouldResetHover")) {
          spaFacebookRef.current.className =
            spaFacebookRef.current.className.replace(
              new RegExp(" shouldResetHover", "g"),
              " resetHover"
            );
        }
        break;
      case 1:
        if (spaInstagramRef.current.className.includes("shouldResetHover")) {
          spaInstagramRef.current.className =
            spaInstagramRef.current.className.replace(
              new RegExp(" shouldResetHover", "g"),
              " resetHover"
            );
        }
        break;
      case 2:
        if (spaWazeRef.current.className.includes("shouldResetHover")) {
          spaWazeRef.current.className = spaWazeRef.current.className.replace(
            new RegExp(" shouldResetHover", "g"),
            " resetHover"
          );
        }
        break;
      case 3:
        if (spaGmapRef.current.className.includes("shouldResetHover")) {
          spaGmapRef.current.className = spaGmapRef.current.className.replace(
            new RegExp(" shouldResetHover", "g"),
            " resetHover"
          );
        }
        break;
      case 4:
        if (spaWhatsapp.current.className.includes("shouldResetHover")) {
          spaWhatsapp.current.className = spaWhatsapp.current.className.replace(
            new RegExp(" shouldResetHover", "g"),
            " resetHover"
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="social-media">
      <div className="social-links">
        <a
          href="https://www.facebook.com/profile.php?id=100087727950250"
          className="spaFacebook"
          target="_blank"
          rel="noreferrer noopener"
          ref={spaFacebookRef}
          onTouchStart={() => onTouchStart(0)}
          onTouchEnd={() => onTouchEnd(0)}
        >
          <FiFacebook />
        </a>
        <a
          href="https://www.instagram.com/silverthaispa.jb/?igshid=YmMyMTA2M2Y%3D"
          className="spaInstagram"
          target="_blank"
          rel="noreferrer noopener"
          ref={spaInstagramRef}
          onTouchStart={() => onTouchStart(1)}
          onTouchEnd={() => onTouchEnd(1)}
        >
          <BsInstagram />
        </a>
        {/* <a
          href="https://www.instagram.com/"
          className="spaWaze"
          target="_blank"
          rel="noreferrer noopener"
          ref={spaWazeRef}
          onTouchStart={() => onTouchStart(2)}
          onTouchEnd={() => onTouchEnd(2)}
        >
          <SiWaze />
        </a> */}
        <a
          href="https://www.google.com/maps/dir/3.0386606,101.703668/Silver+Thai+Spa+@+Plaza+Sentosa/@2.2657927,101.6142854,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x31da6d46ab689d41:0xa9431d8771ee712d!2m2!1d103.7696883!2d1.4926532"
          className="spaGmap"
          target="_blank"
          rel="noreferrer noopener"
          ref={spaGmapRef}
          onTouchStart={() => onTouchStart(3)}
          onTouchEnd={() => onTouchEnd(3)}
        >
          <SiGooglemaps className="spaGmap-plain" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2017 1816"
            width="1em"
            height="1em"
            className="spaGmap-color"
          >
            <path
              d="M831 909.9c37.9 47.4 76.5 107 96.7 143 24.6 46.8 34.8 78.4 53.1 135 10.7 31 20.9 40.4 42.3 40.4 23.4 0 34.1-15.8 42.3-40.4 17.1-53.1 30.3-93.5 51.2-132 80.6-152 212-260 286-408 0 0 48.7-90.4 48.7-217 0-118-48-200-48-200l-572 680z"
              fill="#34a853"
            />
            <path
              d="M637 631.9c46.1 105 134 197 194 278l318-377s-44.9 58.8-126 58.8c-90.4 0-164-72-164-163 0-62.6 37.3-106 37.3-106-234 34.8-221 91.5-260 309z"
              fill="#fbbc04"
            />
            <path
              d="M1153 19.6c106 34.1 196 106 250 211l-254 303s37.3-43.6 37.3-106c0-92.9-78.4-163-163-163-80.3 0-126 58.1-126 58.1 19.5-44.4 221-288 256-303z"
              fill="#4285f4"
            />
            <path
              d="M695 152.9c63.2-75.2 174-153 327-153 73.9 0 130 19.6 130 19.6l-255 303c-17.2-9.33-185-140-202-170z"
              fill="#1a73e8"
            />
            <path
              d="M637 631.9s-41.7-82.8-41.7-202c0-113 44.2-212 100-276l202 170-260 308z"
              fill="#ea4335"
            />
          </svg>
        </a>
        <a
          href="https://wa.me/601121216668"
          target="_blank"
          rel="noreferrer"
          className="spaWhatsapp"
          ref={spaWhatsapp}
          onTouchStart={() => onTouchStart(4)}
          onTouchEnd={() => onTouchEnd(4)}
        >
          <SiWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
