import { gql } from "@apollo/client";

const GET_SINGLE_SPA_DETAIL = gql`
  query getSingleSpaDetail($id: String!) {
    getSingleSpaDetail(id: $id) {
      id
      thumbnail
      title
      description
      price_list {
        price
        duration
      }
    }
  }
`;

export const GetSingleSpaDetail = GET_SINGLE_SPA_DETAIL;
