import "./AboutUs.css";
import "./AboutUs.mobile.css";
import { useQuery } from "@apollo/client";
import { GetAboutUs } from "./GetAboutUs.gql";
import Error from "../Error/Error";
import Loading from "../Loading/Loading";

const AboutUs = () => {
  const { loading, data, error } = useQuery(GetAboutUs);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const { description } = data.getAboutUs;

  return (
    <div className="container">
      <div className="hero-about-us">
        <span className="hero-title">
          <span>Silver Thai Spa</span>
        </span>
        <div className="about-us-content">
          <span className="about-us-description">{description}</span>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
