import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "./SingleSpaDetail.css";
import "./SingleSpaDetail.mobile.css";
import { useQuery } from "@apollo/client";
import { GetSingleSpaDetail } from "./GetSingleSpaDetail.gql";
import { Navigate, useLocation } from "react-router-dom";
import {
  priceFormatter,
  timeFormatter,
} from "../../modules/Formatter/Formatter";
import BottomCarousel from "../BottomCarousel/BottomCarousel";
import Modal from "react-bootstrap/Modal";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import Description from "../Description/Description";

const SingleSpaDetail = () => {
  const location = useLocation();

  const { data, loading, error } = useQuery(GetSingleSpaDetail, {
    variables: {
      id: location.state ? location.state.id : "",
    },
    fetchPolicy: "cache-and-network",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeFullScreenImg, setActiveFullScreenImg] = useState("");

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  //Data destructuring
  const { id, price_list, thumbnail, description, title } =
    data.getSingleSpaDetail;

  const price_list_heading = Object.keys(price_list[0]);

  const newImages = Object.keys(thumbnail).map((key) => ({
    original: thumbnail[key],
  }));

  const openModal = (imgSrc) => {
    setActiveFullScreenImg(imgSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="singleSpaDiv">
      <Container>
        <div className="singleSpaDetailContent">
          <div className="inner">
            <Row md="12">
              <ul className="detail-page-navigation">
                <li>
                  <a href="/" className="detail-page-nav-seperator">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/services" className="detail-page-nav-seperator">
                    Services
                  </a>
                </li>
                <li>
                  <span className="detail-page-navigation-link-active">
                    &nbsp;{title}
                  </span>
                </li>
              </ul>
            </Row>
            <Row md="12">
              <Col md="6">
                <div className="carousel-content">
                  <Carousel
                    showIndicators={false}
                    showStatus={false}
                    showArrows={false}
                    swipeable={true}
                    dynamicHeight={true}
                    thumbWidth={"20%"}
                    autoFocus={true}
                    useKeyboardArrows={true}
                    onClickItem={(index, item) =>
                      openModal(item.props.children.props.src)
                    }
                  >
                    {newImages.map((item, index) => {
                      return (
                        <div key={index}>
                          <img src={item.original} alt={index} />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </Col>
              <Col md="6">
                <div className="detail-content">
                  <span className="detail-title">{title}</span>
                  <div className="massage-description">
                    <Description desc={description} />
                  </div>
                  <div className="price-list">
                    <Col
                      md="12"
                      className="price-list-common price-list-heading"
                    >
                      <Col md="6" className="price-list-item-children">
                        <div className="duration-heading">
                          <span>{price_list_heading[2]}</span>
                        </div>
                      </Col>
                      <Col md="6" className="price-list-item-children">
                        <div className="price-heading">
                          <span>{price_list_heading[1]}</span>
                        </div>
                      </Col>
                    </Col>
                    {price_list.map((price, index) => {
                      return (
                        <Col
                          md="12"
                          className="price-list-common price-list-item"
                          key={index}
                        >
                          <Col md="6" className="price-list-item-children">
                            {timeFormatter(price.duration)}
                          </Col>
                          <Col md="6" className="price-list-item-children">
                            {priceFormatter(price.price)}
                          </Col>
                        </Col>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <BottomCarousel itemId={id} />
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modalImgDiv">
            <img src={activeFullScreenImg} alt="" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SingleSpaDetail;
