import { gql } from "@apollo/client";

const HERO_BANNER = gql`
  query getHeroBanner {
    getHeroBanner {
      thumbnail
    }
  }
`;

export const GetHeroBanner = HERO_BANNER;
