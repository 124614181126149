import "./Hero.css";
import "./Hero.mobile.css";
import { useQuery } from "@apollo/client";
import { GetHeroBanner } from "./Hero.gql";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import AboutUs from "../AboutUs/AboutUs";

const Hero = () => {
  const { loading, data, error } = useQuery(GetHeroBanner);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const { thumbnail } = data.getHeroBanner[0];

  return (
    <div className="heroBannerDiv">
      <img src={thumbnail} alt="Thai Spa Hero Banner" />
    </div>
  );
};

export default Hero;
