import SocialMedia from "../SocialMedia/SocialMedia";
import Copyright from "../Copyright/Copyright";
import { Col } from "react-bootstrap";
import "./Footer.css";
import "./Footer.mobile.css";

const Footer = () => {
  return (
    <Col md="12" className="footer">
      <SocialMedia />
      <Copyright />
    </Col>
  );
};

export default Footer;
