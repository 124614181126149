import "./WhatsappButton.css";
import React from "react";
import { ReactComponent as WhatsappButtonLogo } from "../../whatsapp-icon.svg";

const WhatsappButton = () => {
  var currentHeight = window.scrollY;
  var maxHeight = window.maxHeight - 1;
  console.log(currentHeight);
  return (
    <>
      <a href="https://wa.me/601121216668" target="_blank" rel="noreferrer">
        <div className="whatsappBtnDiv">
          <WhatsappButtonLogo className='whatsappBtn'/>
        </div>
        {/* {currentHeight < 1100 ? (
          <div className="whatsappBtnDiv">
            <WhatsappButtonLogo />
          </div>
        ) : (
          <div className="whatsappBtnDivAlternate">
            <WhatsappButtonLogo />
          </div>
        )} */}
      </a>
    </>
  );
};

export default WhatsappButton;
