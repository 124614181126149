import { FaCogs } from "react-icons/fa";
import './Maintenance.css';

const Maintenance = () => {
    return (
        <div className='maintenanceDiv'>
            <FaCogs className="maintenanceIcon"/>
            <span className='maintenanceTitle'>We are OFFLINE now!</span>
            <span>But rest assured, we will be back soon!</span>
        </div>
    );
}

export default Maintenance;