import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { SendContactUs } from "./ContactUs.gql";
import Snackbar from "@mui/material/Snackbar";
import Spinner from "react-bootstrap/Spinner";
import "./Contact.css";
import Error from "../Error/Error";

const Contact = () => {
  const [validated, setValidated] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  const [contact, setContact] = useState({
    email: "",
    message: "",
  });

  const [createContact, { data, loading, error }] = useMutation(SendContactUs);

  if (error) {
    return <Error />;
  }

  const changeFname = (e) => {
    const { value } = e.target;
    setFname(value.replace(/[^A-Za-z\s]/g, ""));
  };

  const changeLname = (e) => {
    const { value } = e.target;
    setLname(value.replace(/[^A-Za-z\s]/g, ""));
  };

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setIsFormDisabled(true);
      await createContact({
        variables: {
          name: `${fname} ${lname}`,
          email: contact.email,
          message: contact.message,
        },
      });
      setIsFormDisabled(false);
      setFname("");
      setLname("");
      setContact({
        email: "",
        message: "",
      });
      if (data) {
        setIsSnackbarOpen(true);
        if (data.contactUs.status) {
          setSnackbarMessage("Thank you for your submission!");
        } else {
          setSnackbarMessage("An error has occured. Please try again later.");
        }
      }
    }
  };

  const onSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Container className="contact-container" data-aos="fade-up" id="contactUs">
      <Row md="12">
        <span className="contact-us-title">Contact Us</span>
      </Row>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={onSnackbarClose}
        message={snackbarMessage}
        className="contact-us-snackbar"
      />
      <Container>
        <Row className="form-content">
          <Form validated={validated} onSubmit={handleSubmit}>
            <fieldset disabled={isFormDisabled}>
              <Row className="mb-3">
                {/* First Name */}
                <Form.Group as={Col} md="6" controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fname"
                    value={fname}
                    placeholder="John"
                    onChange={(e) => changeFname(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your first name.
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Last Name */}
                <Form.Group as={Col} md="6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lname"
                    value={lname}
                    placeholder="Doe"
                    onChange={(e) => changeLname(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* Email */}
              <Form.Group className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={contact.email}
                  placeholder="Your Email"
                  onChange={inputOnChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide correct e-mail address. Example:
                  thai-spa@gmail.com
                </Form.Control.Feedback>
              </Form.Group>

              {/* Enquiry */}
              <Form.Group className="mb-2">
                <Form.Label>Enquiry</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  value={contact.message}
                  onChange={inputOnChange}
                />
              </Form.Group>

              <Button type="submit" className="submit-button">
                {!isFormDisabled ? (
                  <span>Submit</span>
                ) : (
                  <span>
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="submit-spinner"
                    ></Spinner>
                    Submitting...
                  </span>
                )}
              </Button>
            </fieldset>
          </Form>
        </Row>
      </Container>
    </Container>
  );
};

export default Contact;
