import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Splash from "../Splash/Splash";
import Maintenance from "../Maintenance/Maintenance";
import { useEffect, useState } from "react";
import { ENDPOINT_GQL } from "../../modules/Api/ConstantInterface";

const SharedLayout = () => {
  const location = useLocation();
  const [isMaintenance, setIsMaintenance] = useState(null);

  useEffect(() => {
    async function validateBackend() {
      await fetch(ENDPOINT_GQL)
        .then((response) => response.json())
        .then((data) => {
          setIsMaintenance(false);
        })
        .catch((err) => {
          setIsMaintenance(true);
        });
    }
    validateBackend();
  }, []);

  return (
    <>
      <Header />
      {location.pathname === "/" ? (
        localStorage.getItem("contactUs") ? null : (
          <Splash />
        )
      ) : null}
      {isMaintenance ? <Maintenance /> : <Outlet />}
      <Footer />
    </>
  );
};
export default SharedLayout;
