import "./App.css";
import Home from "./components/Home/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import SingleSpaDetail from "./components/SingleSpaDetail/SingleSpaDetail";
import SharedLayout from "./components/Layout/SharedLayout";
import SpaDetailList from "./components/SpaDetailList/SpaDetailList";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Home />} />
        <Route path="/services" element={<SpaDetailList />} />
        <Route path="/services/:id" element={<SingleSpaDetail />} />
        <Route path="/packages" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

export default App;
