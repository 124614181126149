import { Row, Col } from "react-bootstrap";
import Hero from "../Hero/Hero";
import SpaDetailList from "../SpaDetailList/SpaDetailList";
import Contact from "../Contact/Contact";
import AboutUs from "../AboutUs/AboutUs";

const Home = () => {
  return (
    <Row className="homeDiv">
      <Col md="12">
        <Hero />
      </Col>
      <Col md="12">
        <AboutUs />
      </Col>
      <Col md="12">
        <SpaDetailList />
      </Col>
      <Col md="12">
        <Contact />
      </Col>
    </Row>
  );
};

export default Home;
