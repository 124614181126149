import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./SpaDetail.css";
import { routerSeoMapping } from "../../modules/Router/Router";

const SpaDetail = (props) => {
  const { id, thumbnail, title } = props;
  const navigate = useNavigate();

  return (
    <Col
      md="4"
      className="spaDetailDiv"
      onClick={() =>
        navigate(`/services/${routerSeoMapping(title)}`, { state: { id: id } })
      }
    >
      <div className="spaDetailCont">
        <img
          src={thumbnail}
          className="spaDetailThumbnail"
          alt={title}
          loading="lazy"
        />
        <span className="spaDetailTitle">{title}</span>
      </div>
    </Col>
  );
};

export default SpaDetail;
