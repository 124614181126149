import { gql } from "@apollo/client";

const GET_SPA_DETAIL_LIST = gql`
  query getSpaDetailList {
    getSpaDetailList {
        id
        thumbnail
        title
    }
  }
`;

export const GetSpaDetailList = GET_SPA_DETAIL_LIST;